import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ItemsWrapper, Row, Spacing } from "../style";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { OrderItem } from "../order-item";
import { Scrollbar } from "src/components/scrollbar";

export const ShopeeOrderDetails = ({ setOpen, loading, order, activeChat, setSelectedId }) => {

  const renderOrderItems = (items, type) => {
    const uniqueItems = new Set();

    return (Array.isArray(items) ? items : [])
      .filter(item => {
        const currentItem = type === "messages" ? item.order || item.announcement : item;
        const uniqueId = currentItem?.message_id || currentItem?.id;
        if (uniqueId && !uniqueItems.has(uniqueId)) {
          uniqueItems.add(uniqueId);
          return true;
        }
        return false;
      })
      .map((item, index) => {
        const itemValue = type === "messages" ? item.order || item.announcement : item;
        
        const messageId = item.message_id || item.id;

        return (
          <ItemsWrapper>
            <OrderItem
              key={messageId || `message-${index}`}
              value={itemValue}
              isLast={true}
              loading={loading}
              conversationType={activeChat?.conversation_type}
              setSelectedId={setSelectedId}
              messageId={messageId}
              setOpen={setOpen}
            />
          </ItemsWrapper>
        );
      });
  };

  return (
    <Stack px={1} py={2}>
      <>
        <Row justifyContent="space-between">
          <Typography variant="h6">Detalhe dos items</Typography>
          <Tooltip title="Fechar">
            <IconButton
              sx={{ margin: 0, padding: 0 }}
              color="inherit"
              onClick={() => setOpen(false)}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Row>
        <Spacing direction="vertical" size="0.50rem" />        
          <Scrollbar
            sx={{
              height: "100%",
              "& .simplebar-content": {
                height: "100%",
              },
            }}            
          >
          <Stack gap={2}>
            {renderOrderItems(order?.orders)}
            {renderOrderItems(order?.announcements)}
            {renderOrderItems(activeChat?.messages, "messages")}
          </Stack>  
          </Scrollbar>        
      </>
    </Stack>
  );
};
