import {
  IconButton,
  Tooltip,
  Stack,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { OrderDrawer } from "../order-drawer";
import { neutral } from "src/theme/colors";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as CatalogIcon } from 'src/icons/icons/catalog.svg'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export function translateShopeeStatus(status) {
  switch (status) {
      case "UNPAID":
          return "NÃO PAGO";
      case "READY_TO_SHIP":
      case "PROCESSED":
          return "A ENVIAR";
      case "RETRY_SHIP":
          return "REENVIADO";
      case "SHIPPED":
          return "A CAMINHO";
      case "TO_CONFIRM_RECEIVE":
          return "RECEBIDO";
      case "IN_CANCEL":
          return "EM CANCELAMENTO";
      case "CANCELLED":
          return "CANCELADO";
      case "TO_RETURN":
          return "DEVOLUÇÃO/REEMBOLSO";
      case "COMPLETED":
          return "COMPLETO";
      default:
          return "STATUS DESCONHECIDO";
  }
}

export const ProductInfoFurther = ({ activeChat, accountName, setSelectedId, handleDrawer }) => {
  const desktopDown = useMediaQuery((theme) => theme.breakpoints.down("desktop"));
  const [open, setOpen] = useState(false);
  const { pack_id } = activeChat;
  const orderCode = pack_id
    ? activeChat?.order?.platform_id
    : activeChat?.order?.platform_order_id;

  useEffect(() => {
    setOpen(handleDrawer);
  }, [handleDrawer])

  const announcement = activeChat?.order?.announcement_id ? activeChat?.order?.announcement_id : activeChat?.order?.order_items?.[0].marketplace_announcements_id;
  const isAnnouncement = activeChat?.order?.announcement_id;
  const tooltipTitle = activeChat?.conversation_type === "chats" ? "Filtrar conversa" : "Detalhes do pedido";

  const formatDate = (dateString) => {    
    const createdAt = new Date(dateString);  
    return format(createdAt, "dd/MM/yyyy hh:mm", { locale: ptBR });
  };

  const createdAt = activeChat.conversation_type === "chats" ? 
                    formatDate(activeChat?.order?.createdAt) : 
                    moment(activeChat?.order?.created_at).format("DD/MM/YYYY HH:mm")

  return (
    <>
      {desktopDown ? (
        <Stack
          display="flex"
          flexGrow={1}
          direction="row"
          pt={3}
          pb={1}
          width="100%"
          sx={{ flexWrap: "wrap" }}
        >
          <OrderDrawer open={open} activeChat={activeChat} setOpen={setOpen} />
          <div style={{ fontSize: "14px", textAlign: "left", minWidth: '100%' }}>
            {activeChat?.conversation_type === "chats" && !orderCode ? (
             <>
                <span>Cód. Anuncio:</span>
                <b style={{ marginLeft: '5px' }}>#{announcement}</b>
             </>
            ) : (
              <>
                <span>Cód. Pedido:</span>
                <b style={{ marginLeft: '5px' }}>#{orderCode}</b>
              </>
            )}          
          </div>

          {
            (activeChat?.catalog?.some(item =>
              item?.catalog_listing
            )) ?? false ? (
              <div style={{ fontSize: "14px", textAlign: "left", minWidth: '100%' }}>
                <span>Possui Item de Catálogo</span>
              </div>
            ) : null
          }

          {!isAnnouncement && (
            <div style={{ fontSize: "14px", textAlign: "left", minWidth: '100%' }}>
              <span>Data do Pedido:</span>
              <b style={{ marginLeft: '5px' }}>{createdAt}</b>
            </div>
          )}
          <Stack
            display="flex"
            flexGrow={1}
            direction="row"
            gap={2}
            pt={1}
            pb={1}
            width="100%"
          >
            <div style={{maxWidth: '70%'}}>
            {activeChat?.order?.status && (
              <div style={{ fontSize: "14px", textAlign: "left", minWidth: '100%' }}>
                  <span>Status:</span>
                  <b style={{ marginLeft: '5px' }}>
                      {activeChat.conversation_type === "chats" ? translateShopeeStatus(activeChat.order.status) : activeChat.order.status}
                  </b>
              </div>
            )}    
              <div style={{ fontSize: "14px", textAlign: "left", minWidth: '100%' }}>
                Conta:
                <b style={{ marginLeft: '5px' }}>{accountName ? accountName : activeChat?.account?.account_name}</b>
              </div>
            </div>
            <div style={{maxWidth: '30%', marginTop: '5px'}}>
              <Tooltip title="Detalhes do pedido">
                <IconButton
                  onClick={() => {
                    setOpen((prevState) => !prevState);
                  }}
                  sx={{
                    padding: 0.5,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                {activeChat?.conversation_type === "chats" ? (
                  <ShoppingCartCheckoutIcon
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "light" ? neutral[900] : neutral[50],
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                ) : (
                  <InfoOutlinedIcon
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "light" ? neutral[900] : neutral[50],
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                )}             
                </IconButton>
              </Tooltip>
            </div>
          </Stack>
        </Stack>
      ) : (
        <Stack
          display="flex"
          flexGrow={1}
          direction="row"
          pt={1}
          pr={1}
          pb={0}
          width="100%"
          sx={{ flexWrap: "wrap", position: "" }}
        >
          <OrderDrawer open={open} activeChat={activeChat} setOpen={setOpen} setSelectedId={setSelectedId} />
          
            {activeChat?.conversation_type === "chats" && !orderCode ? (
                <>
                   <p
                    style={{
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      fontSize: "12px",
                      textAlign: "center",
                      marginRight: "16px",
                    }}
                  >
                    Cód. Anúncio:
                    <b>#{announcement}</b>
                  </p>
                </>
            ) : (
                <>
                     <p
                      style={{
                        margin: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        fontSize: "12px",
                        textAlign: "center",
                        marginRight: "16px",
                      }}
                    >
                      Cód. Pedido:
                      <b>#{orderCode}</b>
                    </p>
                </>
            )}
          {activeChat?.order?.status && (
            <p
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                textAlign: "center",
                marginRight: "16px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
              >
                Status:
                <b>{activeChat.conversation_type === "chats"  ? translateShopeeStatus(activeChat?.order?.status) : activeChat?.order?.status}</b>
            </p>
          )}          
          <p
            style={{
              margin: 0,
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              textAlign: "center",
              marginRight: "16px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            Conta:
            <b>{accountName ? accountName : activeChat?.account?.account_name}</b>
          </p>
          {!isAnnouncement && (
            <p
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                textAlign: "center",
                marginRight: "16px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              Data do Pedido:
              <b>
                {createdAt}
              </b>
            </p>
          )}
          {
            (activeChat?.catalog?.some(item =>
              item?.catalog_listing
              )) ?? false ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: desktopDown ? 4 : 0,
                    }}
                  >
                    <CatalogIcon style={{ width: 24, height: "auto" }} fill="#06b6d4" title="" />
                    <b style={{ color: "#06b6d4", marginLeft: 3 }}>Catálogo</b>
                  </span>
              ) : null
          }
            <Tooltip title={tooltipTitle}>
              <IconButton
                //disableRipple={true}
                onClick={() => {
                  setOpen((prevState) => !prevState);
                }}
                sx={{
                  padding: 0.5,
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >

              {activeChat?.conversation_type === "chats" ? (
                <ShoppingCartCheckoutIcon
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "light" ? neutral[900] : neutral[50],
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              ) : (
                <InfoOutlinedIcon
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "light" ? neutral[900] : neutral[50],
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              )}               
              </IconButton>
            </Tooltip>           
        </Stack>
      )}
    </>
  );
};
