import { IconButton, Skeleton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { ItemsWrapper, Row, Spacing } from "../style";
import { Scrollbar } from "src/components/scrollbar";
import { OrderItem } from "../order-item";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import IconThunderbolt from "src/icons/icons/bolt";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import { neutral } from "src/theme/colors";

moment.locale("pt-br");

export const MercadoLivreOrderDetails = ({ order, activeChat, setOpen, success, loading }) => {
    const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));

    const orderItems = order?.order_items;
    const productsPriceTitle =
    orderItems?.length > 1 ? `Valor dos pedidos:` : `Valor do pedido:`;

    const totalValue = parseFloat(order?.total_amount).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    
      const productsPrice = parseFloat(order?.products_price).toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      );
    
      const taxesPrice = parseFloat(order?.taxes_price).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    
      const shippingValue = order?.shipping_cost
        ? parseFloat(order?.shipping_cost).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        : "FRETE GRÁTIS";
    
      const requestDate = moment(order?.request_date).format("DD/MM/YYYY HH:mm");

    return (
        <Stack px={1} py={2}>
        {loading ? (
          <>
            <Row justifyContent="space-between">
              <Row justifyContent="start">
                <Row>
                  <Skeleton variant="circular" width={25} height={25} />
                  <Skeleton variant="rounded" width={210} />
                </Row>
              </Row>
              <Tooltip title="Fechar">
                <IconButton
                  sx={{ margin: 0, padding: 0 }}
                  color="inherit"
                  onClick={() => setOpen(false)}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </Tooltip>
            </Row>
            <Spacing direction="vertical" size="0.25rem" />

            <Typography variant="subtitle1">
              <Skeleton variant="rounded" width={120} />
            </Typography>
            <Spacing direction="vertical" size="1rem" />

            <ItemsWrapper>
              <Scrollbar
                sx={{
                  height: "100%",
                  "& .simplebar-content": {
                    height: "100%",
                  },
                }}
              >
                {activeChat?.order?.order_items?.map((_, index, array) => (
                  <OrderItem
                    loading={loading}
                    key={index}
                    isLast={index === array.length - 1}
                  />
                ))}
              </Scrollbar>
            </ItemsWrapper>

            <Spacing direction="vertical" size="1rem" />
            <Stack spacing={1} px={1}>
              <Row>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={120} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={70} />
                </Typography>
              </Row>
              <Row>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={120} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={70} />
                </Typography>
              </Row>
              <Row>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={120} />
                </Typography>

                <Stack
                  sx={{
                    padding: "1px 0 1px 5px",
                  }}
                >
                  <Skeleton variant="rounded" width={70} />
                </Stack>
              </Row>
              <Row>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={120} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={70} />
                </Typography>
              </Row>
              <Row>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={120} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton variant="rounded" width={70} />
                </Typography>
              </Row>
            </Stack>
          </>
        ) : (
          <>
            {success ? (
              <>
                <Row justifyContent="space-between">
                  <Row justifyContent="start">
                    <Row gap="0.25rem">
                      <LocalShippingIcon />
                      <Typography variant="subtitle1">
                        DETALHES DO PEDIDO
                      </Typography>
                    </Row>
                    {(order.logistic_type !== "crossdocking" && 
                      order.logistic_type !== "drop_off" &&
                      order.logistic_type !== "xd_drop_off" &&
                      order.logistic_type !== "not_specified" &&
                      order.logistic_type !== "") && (
                      <Row gap="0">
                        <IconThunderbolt
                          height="20px"
                          width="20px"
                          fill="#00a650"
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{ fontStyle: "italic" }}
                        >
                          {order.logistic_type === 'flex' ? 'FLEX' : order.logistic_type === 'turbo' ? 'TURBO' : 'FULL'}
                        </Typography>
                      </Row>
                      )}
                  </Row>
                  <Tooltip title="Fechar">
                    <IconButton
                      sx={{ margin: 0, padding: 0 }}
                      color="inherit"
                      onClick={() => setOpen(false)}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Row>

                <Typography variant="subtitle1" color={neutral[500]}>
                  {`#${order.id}`}
                </Typography>
                <Spacing direction="vertical" size="1rem" />

                <ItemsWrapper>
                  <Scrollbar
                    sx={{
                      height: "100%",
                      "& .simplebar-content": {
                        height: "100%",
                      },
                    }}
                  >
                    {orderItems?.map((value, index, array) => (
                      <OrderItem
                        activeChat={activeChat}
                        value={value}
                        key={index}
                        isLast={index === array.length - 1}
                      />
                    ))}
                  </Scrollbar>
                </ItemsWrapper>

                <Spacing direction="vertical" size="1rem" />               
                  <Stack spacing={1} px={1}>
                   <Row>
                     <Typography variant="subtitle1">
                       {productsPriceTitle}
                     </Typography>
                     <Typography variant="subtitle1">{productsPrice}</Typography>
                   </Row>
 
                   <Row>
                     <Typography variant="subtitle1">Tarifas:</Typography>
                     <Typography variant="subtitle1">{taxesPrice}</Typography>
                   </Row>
 
                   <Row>
                     <Typography variant="subtitle1">Valor do frete:</Typography>
                     <Typography
                       variant="subtitle1"
                       color={order.customer_shipping_cost ? "" : "success.main"}
                     >
                       {shippingValue}
                     </Typography>
                   </Row>
                   {order.shipping_paid_by && (
                     <Row>
                       <Typography variant="subtitle1">Frete pago por:</Typography>
                       <Typography
                         variant="subtitle1"
                       >
                         {order.shipping_paid_by === "customer"
                           ? "Comprador"
                           : "Vendedor"}
                       </Typography>
                     </Row>
                   )}
 
                   <Row>
                     <Typography variant="subtitle1">Valor total:</Typography>
                     <Stack
                       sx={{
                         backgroundColor: "success.main",
                         padding: "1px 5px 1px 5px",
                         borderRadius: "5px",
                       }}
                     >
                       <Typography
                         variant="subtitle1"
                         color="primary.contrastText"
                       >
                         {totalValue}
                       </Typography>
                     </Stack>
                   </Row>
                   <Row>
                     <Typography variant="subtitle1">
                       Informações de logística:
                     </Typography>
                     <Typography variant="subtitle1">
                       {order.logistic_type
                         ? `Entrega ${order.logistic_type}`
                         : "Entrega padrão"}
                     </Typography>
                   </Row>
                   <Row>
                     <Typography variant="subtitle1">Data do pedido:</Typography>
                     <Typography variant="subtitle1">{requestDate}</Typography>
                   </Row>
                   {/* <Row>
                 <Typography variant="subtitle1">Data de envio</Typography>
                 <Typography variant="subtitle1">04/08/2023 04:25</Typography>
               </Row> */}
                 </Stack>                          
              </>
            ) : (
              <>
                <Typography variant="h5">
                  Não foi possível recuperar os detalhes do pedido
                </Typography>
                <Spacing direction="vertical" size="100px" />
                <Row justifyContent="center">
                  <IconButton onClick={() => getOrders()}>
                    <RefreshIcon fontSize="large" color="primary" />
                  </IconButton>
                </Row>
                <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                  Tente novamente
                </Typography>
              </>
            )}
          </>
        )}
      </Stack>
    );
  };